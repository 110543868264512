<template>
<div>
  <div class="w3-display-middle">
    <h1 class="w3-jumbo w3-animate-top w3-center"><code>Access Denied</code></h1>
    <hr class="w3-border-white w3-animate-left">
    <h3 class="w3-center w3-animate-right">You dont have permission to view this moudle.</h3>
    <h3 class="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
  </div>
</div>
</template>

<script>
export default {
name: "AccessDenied"
}
</script>

<style scoped>
.w3-display-middle {
  text-align: center;
  position: absolute;
  /* width: 100%; */
  left: 50%;
  top: 50%;
  transform: translate(0%, -50%);
}
body{
  background-color: black;
  color: white;
}

h1 {
  color: red;
}

h6{
  color: red;
  text-decoration: underline;
}

</style>